import React from 'react';

const CrioMarketingPages = (props) => {
  const { baseUrl, slug } = props.pageContext;

  let search = '';

  if (typeof window !== 'undefined') {
    search = window.location?.search;
  }

  return (
    <div className="h-[100dvh] w-[100dvw]">
      <iframe
        title="Embedded Page"
        src={`${baseUrl}/${slug}/${search}`}
        style={{ width: '100%', height: '100%' }}
      ></iframe>
    </div>
  );
};

export default CrioMarketingPages;
